import { ofDayInMillis, ofHourInMillis } from '@/utilities/timeUtils';
import './MeasureChannelAgeIndicator.css';

export default function MeasureChannelAgeIndicator({
  currentTimeMicros,
  measureTimeMicros,
}: {
  currentTimeMicros: number;
  measureTimeMicros: number;
}) {
  if (measureTimeMicros == -9223372036854776000) {
    return <div className="measure-channel-age-indicator"></div>;
  }

  const measureChannelAgeMillis = (currentTimeMicros - measureTimeMicros) / 1000;
  let measureChannelAgeState = 'very-old';

  if (measureChannelAgeMillis < ofHourInMillis(1)) {
    measureChannelAgeState = 'very-fresh';
  } else if (measureChannelAgeMillis < ofDayInMillis(1)) {
    measureChannelAgeState = 'fresh';
  } else if (measureChannelAgeMillis < ofDayInMillis(3)) {
    measureChannelAgeState = 'middle';
  } else if (measureChannelAgeMillis < ofDayInMillis(7)) {
    measureChannelAgeState = 'old';
  }

  return <div className={'measure-channel-age-indicator measure-channel-age-' + measureChannelAgeState}></div>;
}
