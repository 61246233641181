import { ReactElement, createContext, useState } from 'react';
import Notification from '@/components/news/Notification';
import { AlertColor } from '@mui/material';

type NotificationMessage = {
  severity: AlertColor;
  message: string;
  onClose?: () => void;
};

export type NotificationContextType = {
  showNotification: (severity: AlertColor, message: string, onClose?: () => void, timeoutSec?: number) => void;
};

// eslint-disable-next-line react-refresh/only-export-components
export const ctxNotification = createContext<NotificationContextType | undefined>(undefined);

export function NotificationProvider({ children }: { children: ReactElement }) {
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage[]>([]);

  const hideNotification = (message: string) => {
    notificationMessage.forEach((value) => {
      if (value.message === message) {
        value.onClose?.();
      }
    });
    setNotificationMessage((prev) => prev.filter((value) => value.message !== message));
  };

  const showNotification = (severity: AlertColor, message: string, onClose?: () => void, timeoutSec?: number) => {
    const newNotificationMessage: NotificationMessage = {
      severity,
      message,
      onClose,
    };
    setNotificationMessage((prev) => {
      if (timeoutSec) {
        setTimeout(
          () =>
            setNotificationMessage((prev) => prev.filter((value) => value.message != newNotificationMessage.message)),
          timeoutSec * 1000
        );
      }
      const containes = prev.find((value) => value.message === newNotificationMessage.message);
      if (!containes) {
        return [...prev, newNotificationMessage];
      }
      return [...prev];
    });
  };

  return (
    <ctxNotification.Provider value={{ showNotification }}>
      <div>
        {notificationMessage.map((notification) => (
          <Notification
            key={notification.message}
            message={notification.message}
            close={hideNotification}
            severity={notification.severity}
          />
        ))}
      </div>
      {children}
    </ctxNotification.Provider>
  );
}
