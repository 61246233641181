import { Dispatch, ReactElement, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { TerminalMessageInITrack } from '@/generated/model/terminalMessageInITrack';
import { getNewMessages } from '@/generated/client/messages/messages';
import { ThreadUserThreadId } from '@/types';
import { ctxUser } from './UserContext';
import { rethrowIfNotClientException } from '@/utilities/throwableUtils';

export type MessagesContextType = {
  sentMessage: TerminalMessageInITrack | undefined;
  setSentMessage: Dispatch<SetStateAction<TerminalMessageInITrack | undefined>>;

  thread: ThreadUserThreadId | undefined;
  setThread: Dispatch<SetStateAction<ThreadUserThreadId | undefined>>;
  newMessages: Map<number, TerminalMessageInITrack[]>;

  searchText: string | undefined;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const ctxMessages = createContext<MessagesContextType | undefined>(undefined);

export function MessagesContext({ children }: { children: ReactElement }) {
  const [sentMessage, setSentMessage] = useState<TerminalMessageInITrack | undefined>(undefined);
  const [newMessages, setNewMessages] = useState<Map<number, TerminalMessageInITrack[]>>(new Map());
  const [thread, setThread] = useState<ThreadUserThreadId | undefined>(undefined);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const userCtx = useContext(ctxUser);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;

    function setTimeoutForPoll(fromTime: number) {
      timer = setTimeout(() => poll(fromTime), 60000);
    }

    async function poll(fromTime: number) {
      try {
        const result = await getNewMessages({
          asynchronous: false,
          fromTime,
        });

        if (result.status === 200 && result.data.messages.length) {
          const receivedMessages = result.data.messages;
          if (receivedMessages && receivedMessages.length) {
            const newMap = new Map<number, TerminalMessageInITrack[]>();
            receivedMessages.forEach((message) => {
              if (newMap.has(message.threadId)) {
                newMap.get(message.threadId)?.push(message);
              } else {
                newMap.set(message.threadId, [message]);
              }
            });
            setNewMessages(newMap);
          }
          setTimeoutForPoll(result.data.serverTimeMicros);
        } else {
          setTimeoutForPoll(fromTime);
        }
      } catch (error) {
        setTimeoutForPoll(fromTime);
        rethrowIfNotClientException(error);
      }
    }

    if (userCtx?.isToken()) return;

    poll(-1);

    return () => {
      clearTimeout(timer);
    };
  }, [userCtx]);

  return (
    <ctxMessages.Provider
      value={{
        sentMessage,
        setSentMessage,
        thread,
        setThread,
        newMessages,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </ctxMessages.Provider>
  );
}
