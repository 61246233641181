import axios, { AxiosResponse } from 'axios';
import Bottleneck from 'bottleneck';

import { Target, TargetDescription, TargetRequest, TrackableTargetsResponse } from './Target';
import { Route, PositionList } from './Route';
import { Refuel } from './Refuel';
import { SelectedRoute } from '@/types';
import { TerminalMessageThreadInITrack } from '@/generated/model/terminalMessageThreadInITrack';

type GenerateReply = {
  loginToken: string;
}

const limiter = new Bottleneck({
  minTime: 5,
  maxConcurrent: 1,
});

let controllerTracking: AbortController | undefined = undefined;

export async function tracking(targets: TargetRequest[], popupTargetId: number | undefined): Promise<AxiosResponse<Target[] | undefined>> {
  if (controllerTracking) {
    controllerTracking.abort();
  }
  controllerTracking = new AbortController();

  const url = popupTargetId && targets.find(t => t.targetId === popupTargetId) !== undefined
    ? `/api/v1.1/targets/positions/tracking?popupTargetId=${popupTargetId}`
    : '/api/v1.1/targets/positions/tracking';

  return axios.post<Target[]>(url, targets, {
    signal: controllerTracking.signal,
  });
}

export async function abortTracking() {
  if (controllerTracking) {
    controllerTracking.abort();
    controllerTracking = undefined;
  }
  return;
}

let controllerTarget: AbortController | undefined = undefined;

export async function targetList(
  searchText: string | undefined,
  offset: number,
  limit: number
): Promise<AxiosResponse<TargetDescription[] | undefined>> {

  const params = new URLSearchParams();
  params.append('offset', String(offset));
  params.append('limit', String(limit));
  if (searchText) {
    params.append("filter", searchText);
  }

  // FIXME kellene lassitas bele
  const url = `/api/v1.1/targets?${params.toString()}`;

  return limiter.schedule(() => axios.get<TargetDescription[]>(url));
}

export async function allTrackableTargetList(
  searchText: string | undefined
): Promise<AxiosResponse<TrackableTargetsResponse | undefined>> {
  const params = new URLSearchParams();
  if (searchText) {
    params.append("filter", searchText);
  }
  const url = `/api/v1.1/targets/all/trackable?${params.toString()}`;

  if (controllerTarget) {
    controllerTarget.abort();
  }
  controllerTarget = new AbortController();

  return axios.get<TrackableTargetsResponse>(url, {
    signal: controllerTarget.signal,
  }).finally(() => {
    controllerTarget = undefined;
  });
}

export async function trackedTargetLimit(): Promise<AxiosResponse<number | undefined>> {
  const url = '/api/v1.1/targets/trackedTargetLimit';

  return axios.get<number>(url);
}

export async function routes(
  target: TargetDescription,
  from: Date,
  to: Date
) {
  const fromTime = `${from.getTime()}000`;
  const toTime = `${to.getTime()}000`;
  const url = `/api/v1.1/targets/${target.id}/routes?fromTime=${fromTime}&toTime=${toTime}`;
  return axios.get<Route[]>(url);
}

export async function positions(
  routes: SelectedRoute[]
) {
  const request: RoutesRequest = toRoutesRequest(routes);
  const url = `/api/v1.1/targets/${request.targetId}/positions?fromTime=${request.fromTimeMicros}&toTime=${request.toTimeMicros}&routeIds=${request.routeIds}`;
  return axios.get<PositionList[]>(url);
}

function toRoutesRequest(routes: SelectedRoute[]) {
  const routeIds: number[] = [];
  let targetId: number  = 0;
  let fromTimeMicros: number = Number.MAX_VALUE;
  let toTimeMicros: number = 0;

  routes.forEach(route => {
    if (targetId && targetId !== route.target.id) {
      throw "Get routes of multiple targets not supported";
    }
    targetId = route.target.id;
    routeIds.push(route.id);
    if (!fromTimeMicros || fromTimeMicros > route.fromTimeMicros) {
      fromTimeMicros = route.fromTimeMicros;
    }
    if (!toTimeMicros || toTimeMicros < route.toTimeMicros) {
      toTimeMicros = route.toTimeMicros;
    }
  });
  return { routeIds: routeIds, targetId: targetId, fromTimeMicros: fromTimeMicros, toTimeMicros: toTimeMicros };
}

type RoutesRequest = {
  routeIds: number[];
  targetId: number;
  fromTimeMicros: number;
  toTimeMicros: number;
};

export async function refuels(
  target: TargetDescription,
  from: Date,
  to: Date
) {
  const fromTime = `${from.getTime()}000`;
  const toTime = `${to.getTime()}000`;
  const url = `/api/v1.1/targets/${target.id}/fuelfillings?fromTime=${fromTime}&toTime=${toTime}&offset=0&limit=25`;
  return axios.get<Refuel[]>(url);
}

export async function recentMessages() {
  const url = `/api/v1.1/messageThreads/recent?threadLimit=10&messagesByThreadLimit=1`;
  return axios.get<TerminalMessageThreadInITrack[]>(url);
}

export async function chatMessages(name: string) {
  const url = `/api/v1.1/messageThreads/threadUser?name=${name}&messagesByThreadLimit=10`;
  return axios.get<TerminalMessageThreadInITrack[]>(url);
}

export async function generate(
  targetId: number,
  fromTimeMicros: number,
  toTimeMicros: number,
  emailAddress: string | undefined,
) {
  const data = {
    targetId,
    fromTimeMicros,
    toTimeMicros,
    emailAddress,
    type: 'tracking',
  }

  const url = '/api/v1.1/urls/generate';
  const reply = await axios.post<GenerateReply>(url, data);
  return reply.data.loginToken;
}

