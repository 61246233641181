import { Target } from '@/tmp';
import { calculateDuration, getTimeInMicros, timeDurationShort } from '@/utilities/timeUtils';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import './TargetMeasureChannels.css';
import MeasureChannelAgeIndicator from './MeasureChannelAgeIndicator';

export default function TargetMeasureChannels({ target }: { target: Target }) {
  const { t } = useTranslation();

  if (!target.currentPosition.measures || !target.currentPosition.measures.length) {
    return;
  }

  const domPurifyOptions = {
    USE_PROFILES: { html: true },
  };
  const currentTimeMicros = getTimeInMicros();
  // lehet h a container is scrollozos es kulon is teszunk ra ??
  return (
    <div className="target-measure-channels">
      <TableContainer component={Paper}>
        <Table className="measure-channel-table">
          <TableBody>
            {target.currentPosition.measures.map((measure) => (
              <TableRow key={measure.id}>
                <TableCell className="measure-channel-name">{measure.name}</TableCell>
                <TableCell>
                  <div className="measure-channel-value">
                    <MeasureChannelAgeIndicator
                      currentTimeMicros={currentTimeMicros}
                      measureTimeMicros={measure.timestampMicros}
                    />
                    <span
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(measure.value, domPurifyOptions) }}
                    ></span>
                  </div>
                  <div className="measure-channel-date">
                    {measure.timestampMicros > 0
                      ? timeDurationShort(t, calculateDuration(measure.timestampMicros, currentTimeMicros), 2)
                      : ' '}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
