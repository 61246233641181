import { Dispatch, SetStateAction, ReactElement, createContext } from 'react';

export type PopupContext = {
  setPopupMain: Dispatch<SetStateAction<ReactElement | undefined>>;
  setPopupSecondary: Dispatch<SetStateAction<ReactElement | undefined>>;
  setPopupLoader: Dispatch<SetStateAction<ReactElement | undefined>>;

  showLoader: () => void;
  hideLoader: () => void;
};

export const ctxPopup = createContext<PopupContext | undefined>(undefined);
