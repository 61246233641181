export class PlutoClientException extends Error {
  url: string;
  statusCode?: number;
  statusText?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, request: any) {
    super(message);
    this.name = 'PlutoClientException';
    this.url = request.responseURL;
    this.statusCode = request.status;
    this.statusText = request.statusText;
    Object.setPrototypeOf(this, PlutoClientException.prototype);
  }

  public toString() {
    return (
      super.toString() + ' [' + this.url + (this.statusCode ? ', ' + this.statusCode + ' ' + this.statusText : '') + ']'
    );
  }
}
