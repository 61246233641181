import { format, isToday, isYesterday, isThisYear } from 'date-fns';
import { TFunction } from 'i18next';
import { getLocale } from '@/components/common/translateFunctions';

const patternYMD = 'yyyy-MM-dd';
const patternYMDHM = 'yyyy-MM-dd HH:mm';
const patternYMDHMS = 'yyyy-MM-dd HH:mm:ss';
const pattern = 'hu-HU';

export function date(date: Date) {
  return format(date, patternYMD);
}

export function dateDot(dateFrom: Date, dateTo: Date) {
  return dateFrom.toLocaleDateString(pattern) + ' - ' + dateTo.toLocaleDateString(pattern);
}

export function dateMD(dateFrom: Date, dateTo: Date, language: string) {
  return (
    dateFrom.toLocaleDateString(language, {
      month: 'short',
      day: 'numeric',
    }) +
    ' - ' +
    dateTo.toLocaleDateString(language, {
      month: 'short',
      day: 'numeric',
    })
  );
}

export function fulldate(timeMicros: number) {
  return format(new Date(timeMicros / 1000), patternYMDHMS);
}

export function chatdate(timeMicros: number, currentLanguage: string, translateFunctions: TFunction) {
  const date = new Date(timeMicros / 1000);
  const currentLocale = getLocale(currentLanguage);

  let relTime = format(date, patternYMDHM, { locale: currentLocale });
  if (isToday(date)) {
    relTime = translateFunctions('general.dateTime.today') + ', ' + format(date, 'HH:mm', { locale: currentLocale });
  } else if (isYesterday(date)) {
    relTime =
      translateFunctions('general.dateTime.yesterday') + ', ' + format(date, 'HH:mm', { locale: currentLocale });
  } else if (isThisYear(date)) {
    relTime = format(date, 'MMM dd. (E), HH:mm', { locale: currentLocale });
  } else {
    relTime = format(date, patternYMDHM, { locale: currentLocale });
  }

  return relTime;
}
