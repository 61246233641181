import { createContext } from 'react';
import { User } from '../tmp';

export type UserContext = {
  user: User;
  isToken: () => boolean;
  hasExtraFeature: (extraFeature: string) => boolean;
};

export const ctxUser = createContext<UserContext | undefined>(undefined);
