import { Duration, Locale, format, formatDistance, formatDuration, intervalToDuration } from 'date-fns';

function getTimeInMillis(): number {
  return new Date().getTime();
}

export function getTimeInMicros(): number {
  return getTimeInMillis() * 1000;
}

export function ofHourInMillis(hours: number): number {
  return hours * 3600000;
}

export function ofDayInMillis(days: number): number {
  return days * 86400000;
}

export function calculateDuration(fromTimeMicros: number, toTimeMicros: number): number {
  return Math.abs(toTimeMicros - fromTimeMicros);
}

export function day(timeMicros: number) {
  return format(new Date(timeMicros / 1000), 'yyyy-MM-dd');
}

export function time(timeMicros: number) {
  return format(new Date(timeMicros / 1000), 'HH:mm:ss');
}

export function timeDistance(start: Date, end: Date) {
  return formatDuration(intervalToDuration({ start, end }));
}

export function timeDistanceWithRound(locale: Locale, start: Date, end: Date) {
  return formatDistance(start, end, { locale });
}

function partsOfDurationTimeMicros(durationTimeMicros: number): Duration {
  return intervalToDuration({ start: 0, end: durationTimeMicros / 1000 });
}

export function timeDuration(duartionTimeMicros: number) {
  return formatDuration(partsOfDurationTimeMicros(duartionTimeMicros));
}

export function timeDurationShort(t: (key: string) => string, durationTimeMicros: number, partLimit?: number) {
  const duration: Duration = partsOfDurationTimeMicros(durationTimeMicros);

  let formatedTimeDuration = [
    formatIfValidValue(t, duration.years, 'year'),
    formatIfValidValue(t, duration.months, 'month'),
    formatIfValidValue(t, duration.days, 'day'),
    formatIfValidValue(t, duration.hours, 'hour'),
    formatIfValidValue(t, duration.minutes, 'minute'),
    formatIfValidValue(t, duration.seconds, 'second'),
  ];

  while (formatedTimeDuration.length > 0 && formatedTimeDuration[0] == undefined) {
    formatedTimeDuration.shift();
  }

  if (partLimit) {
    formatedTimeDuration = formatedTimeDuration.slice(0, partLimit);
  }

  return formatedTimeDuration.join(' ');
}

function formatIfValidValue(t: (key: string) => string, value: number | undefined, unitKey: string) {
  if (value && value > 0) {
    const translationKey = 'general.dateTime.' + unitKey;
    return value.toString() + t(translationKey);
  }
}
