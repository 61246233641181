export function isRunningInApp() {
  return window.Logout !== undefined;
}

export function isPhoneScreen() {
  return window.outerWidth < 768 || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

// EZT HASZNÁLD!!
export function isPhoneOrHighScreen() {
  return isPhoneScreen() || window.outerHeight > window.outerWidth;
}
