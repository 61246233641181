import { ReactElement, useState } from 'react';

import { ctxPopup } from '@/contexts/PopupContext';
import Loader from '@/components/common/Loader';

export default function Popups({ children }: { children: ReactElement }) {
  const [popupMain, setPopupMain] = useState<ReactElement | undefined>(undefined);
  const [popupSecondary, setPopupSecondary] = useState<ReactElement | undefined>(undefined);
  const [popupLoader, setPopupLoader] = useState<ReactElement | undefined>(undefined);

  // TODO ne lehessen secondary-t masikkal felulirni ?
  // TODO lehessen tobb notification is ??
  // szamolnia spinner kerest/torlest ?

  function showLoader() {
    setPopupLoader(<Loader />);
  }
  function hideLoader() {
    setPopupLoader(undefined);
  }

  return (
    <ctxPopup.Provider
      value={{
        setPopupMain,
        setPopupSecondary,
        setPopupLoader,
        showLoader: showLoader,
        hideLoader: hideLoader,
      }}
    >
      {popupMain}
      {popupSecondary}
      {popupLoader}
      {children}
    </ctxPopup.Provider>
  );
}
