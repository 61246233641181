import { useContext, useEffect, useState } from 'react';

import { UserMessage } from '@/types';
import { getUser } from '@/tmp';
import { ctxUser } from '@/contexts/UserContext';
import { rethrowIfNotClientException } from '@/utilities/throwableUtils';

export function useUserMessage(): UserMessage | undefined {
  const userCtx = useContext(ctxUser);
  const [message, setMessage] = useState<UserMessage | undefined>(undefined);

  useEffect(() => {
    const oneHourMillis = 60 * 60 * 1000;
    const timeFrameMillis = 24 * oneHourMillis; // 24 hours

    async function getMessage() {
      try {
        const response = await getUser();
        if (response.status === 200) {
          const userAndMessage = response.data;
          setMessage({
            message: userAndMessage.userMessage,
            date: Date.now(),
          });
        } else {
          setMessage(undefined);
        }
      } catch (error) {
        setMessage(undefined);
        rethrowIfNotClientException(error);
      }
    }

    if (userCtx?.isToken()) return;

    const timer = setInterval(getMessage, timeFrameMillis);
    getMessage();

    return () => clearInterval(timer);
  }, [userCtx]);

  return message;
}
