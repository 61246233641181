import { ctxPluto } from '@/contexts/TargetContext';
import { useContext } from 'react';
import TargetMeasureChannels from './TargetMeasureChannels';

import './TargetTrackingPanel.css';
import TargetTrackingDetails from './TargetTrackingDetails';

export default function TargetTrackingPanel() {
  const ctx = useContext(ctxPluto);

  if (ctx?.popupTargetId === null || ctx?.mode !== 'target') {
    return null;
  }
  const target = ctx.fullTargets?.find((t) => t.target.id === ctx?.popupTargetId);
  if (!target) {
    return null;
  }

  function onTargetReload() {
    ctx?.setImmediateLoadTargetId(ctx?.popupTargetId);
  }

  function onTargetClose() {
    ctx?.setPopupTargetId(undefined);
  }

  return (
    <div className="target-tracking-panel">
      <TargetTrackingDetails target={target} onTargetReload={onTargetReload} onTargetClose={onTargetClose} />
      <TargetMeasureChannels target={target} />
    </div>
  );
}
