import { ctxNotification } from '@/contexts/NotificationCtx';
import { ContentPaste } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default function CopyToClipboardButton({ contentText }: { contentText: string }) {
  const ctxNotify = useContext(ctxNotification);
  const { t } = useTranslation();

  const onCopyToClipboard = async () => {
    await navigator.clipboard.writeText(contentText);
    ctxNotify?.showNotification('info', t('general.copiedClipboard'), undefined, 3);
  };

  return (
    <>
      <IconButton color="primary" size="small" onClick={onCopyToClipboard}>
        <ContentPaste fontSize="small" />
      </IconButton>
    </>
  );
}
