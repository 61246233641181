import { Locale } from 'date-fns';
import { enGB, hu, sk, ro, sr, hr, cs, sl } from 'date-fns/locale';
import availableLanguagesRaw from './availableLanguages.json';

export type Language = {
  code: string;
  name: string;
  order: number;
};

export function getLocale(currentLanguage: string): Locale {
  const languages: string[] = getAvailableLanguages().map((l) => l.code);

  if (!languages.includes(currentLanguage)) {
    return enGB;
  }

  switch (currentLanguage) {
    case 'en':
      return enGB;
    case 'hu':
      return hu;
    case 'sk':
      return sk;
    case 'ro':
      return ro;
    case 'sr':
      return sr;
    case 'hr':
      return hr;
    case 'cz':
      return cs;
    case 'sl':
      return sl;
    default:
      return enGB;
  }
}

export function getAvailableLanguages(): Language[] {
  const availableLanguages = availableLanguagesRaw satisfies Language[];
  availableLanguages.sort((a: Language, b: Language) => a.order - b.order);
  return availableLanguages;
}
